import { Injectable } from '@angular/core';
import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettingsService } from '@core/services/app-settings/app-settings.service';
import { Router, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { constants } from '@shared/constants';

@Injectable({
	providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
	private mockApiUrl: string;

	constructor(
		private appSettingsService: AppSettingsService,
		private router: Router,
		private route: ActivatedRoute
	) {
		this.router.events
			.pipe(
				map(() => this.rootRoute(this.route)),
				filter((route: ActivatedRoute) => route.outlet === 'primary')
			)
			.subscribe((route: ActivatedRoute) => {
				this.mockApiUrl = route.snapshot.queryParamMap.get('mockApiUrl') ?? '';
			});
	}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const token = sessionStorage.getItem(constants.jwtKeyName);

		if (this.mockApiUrl) {
			const apiUrl = this.appSettingsService.appSettings.invitationsApiUrl;
			const mockApiUrl = this.mockApiUrl;
			request = request.clone({
				url: request.url.replace(apiUrl, mockApiUrl),
			});
		}

		if (token) {
			request = request.clone({
				headers: request.headers.set('Authorization', `Bearer ${token}`),
			});
		}

		if (!request.headers.has('Content-Type')) {
			request = request.clone({
				headers: request.headers.set('Content-Type', 'application/json'),
			});
		}

		request = request.clone({
			headers: request.headers.set('Accept', 'application/json'),
		});

		return next.handle(request);
	}

	private rootRoute(route: ActivatedRoute): ActivatedRoute {
		while (route.firstChild) {
			route = route.firstChild;
		}
		return route;
	}
}
