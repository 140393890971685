import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationExtras } from '@angular/router';
import { LanguageService } from '@core/services/language/language.service';
import { InvitationValidation } from '@models/invitations.model';
import { InvitationsService } from '@services/invitations/invitations.service';
import { LoggerService } from '@services/logging/logger.service';
import { constants } from '@shared/constants';
import { RowStatus } from '@shared/enums';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ValidGuard implements CanActivate {

  private readonly moduleName = 'ValidGuard';

  constructor(
      private invitationService: InvitationsService,
      private router: Router,
      private languageService: LanguageService,
      private logger: LoggerService
  ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> {
        
            const metadataToken = encodeURIComponent(next.params.metadataToken);
            if(!metadataToken){
              return of(false);
            }
            sessionStorage.setItem(constants.metadataToken, metadataToken);
            return this.invitationService.validate(metadataToken).pipe(
              map((res: InvitationValidation) => {
                this.languageService.setLanguage(res.langCode);
                const extras = {
                  queryParams:{
                    doctorName: res.doctorName,
                    clinicName: res.clinicName
                  }
                } as NavigationExtras;

                if(res && res.rowStatus === RowStatus.InActive || res.rowStatus === RowStatus.Deleted){
                  this.logger.warn(`Navigating to /expired page. Invitation Expired. Expired token:  ${res.token}`,  { module: this.moduleName });
                  this.router.navigate(['/expired'], extras);
                  return false;
                } else if (res && (res.rowStatus === RowStatus.Resolved || res.rowStatus === RowStatus.Closed)) {
                  this.logger.info(`Navigating to /completed page. Invitation Process Resolved. Invitation token:  ${res.token}`,  { module: this.moduleName });
                  this.router.navigate(['/completed'], extras);
                  return false;
                }

                const isInvitationActive = res?.rowStatus === RowStatus.Active;
                this.logger.debug(`invitation is${isInvitationActive ? '' : 'not'} valid. Token: ${res.token}`,  { module: this.moduleName });

                return isInvitationActive;
              })
            );
      }
}