import { Injectable } from '@angular/core';
import { ApiRoutes } from '@core/api-routes/api-routes.service';
import { HttpHandlerService } from '@core/services/http-handler/http-handler.service';
import { Invitation, InvitationValidation } from '@models/invitations.model';
import { constants } from '@shared/constants';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InvitationsService {
  private _invitation$: Observable<Invitation>;

  constructor(private httpHandler: HttpHandlerService) {}

  get metadataToken(): string {
    return sessionStorage.getItem(constants.metadataToken) ?? '';
  }

  get invitation(): Observable<Invitation> {
    if (!this._invitation$) {
      this._invitation$ = this.getInvitation().pipe(shareReplay(1));
    }

    return this._invitation$;
  }

  getInvitation(): Observable<Invitation> {
    return this.httpHandler
      .get<Invitation>(ApiRoutes.Invitations.get(this.metadataToken))
      .pipe(tap((res) => res));
  }

  validate(metadataToken: string): Observable<InvitationValidation> {
    return this.httpHandler.get<InvitationValidation>(
      ApiRoutes.Invitations.validate(metadataToken)
    );
  }
}
