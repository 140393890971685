import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseDestroyable } from '@core/base-destroyable';
import { AppSettingsService } from '@core/services/app-settings/app-settings.service';
import { LanguageService } from '@core/services/language/language.service';
import { Language } from '@models/language.model';
import { LoggerService } from '@services/logging/logger.service';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class AppToolbarComponent
  extends BaseDestroyable
  implements OnInit, OnDestroy
{
  private readonly moduleName = 'AppToolbarComponent';
  languages: Language[];
  selectedLanguage: string;

  constructor(
    private appSettingsService: AppSettingsService,
    private languageService: LanguageService,
    private http: HttpClient,
    private logger: LoggerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.languages = this.appSettingsService.appLocaleData.languages;
    this.languageService.languageChanged$
      .pipe(takeUntil(this.componentAlive$))
      .subscribe((language: Language) => {
        this.selectedLanguage = language.code;
      });
  }

  languageChanged(languageCode: string): void {
    this.languageService.setLanguage(languageCode);
  }

  showVersion() {
    this.logger.debug(`Loading app version.`, { module: this.moduleName });

    this.http.get('/assets/version.json').pipe(tap(
      (res: any) => {
        this.logger.info(`Successfully loaded app version.`, { module: this.moduleName });
        alert(`version: ${res.version}`);
      },
      (err) => {
        const errorMessage = err?.message ?? err;
        this.logger.error(`Error. Cannot get app version. Error stack: ${errorMessage}`, { module: this.moduleName });
        alert('cannot get version');
      }
    ), takeUntil(this.componentAlive$)).subscribe();
  }
}
