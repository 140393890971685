import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorHandlerService } from '@core/services/error-handling/error-handler.service';
import { HttpInterceptorService } from '@core/interceptors/http-interceptor.service';
import { AppSettingsService } from '@core/services/app-settings/app-settings.service';
import { ValidGuard } from '@core/guards/valid/valid.guard';
import { IteroUserGuard } from '@core/guards/iTero-user/iTero-user.guard';
import { SessionStorageGuard } from '@core/guards/session-storage/session-storage.guard';
import { LoaderInterceptor } from '@services/spinner/loader.interceptor';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpTranslateLoader,
				deps: [ HttpClient ]
			}
		}),
  ],
  providers:[
    {
      provide: ErrorHandler, 
      useClass: ErrorHandlerService
    },
    { 
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService, 
      multi: true 
    },
    { 
			provide: HTTP_INTERCEPTORS,
			useClass: LoaderInterceptor,
			 multi: true 
		},
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppSettingsService],
      useFactory: (appSettingsService: AppSettingsService) => {
        return () => {
          //Make sure to return a promise!
          return appSettingsService.loadAppSettings();
        };
      }
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppSettingsService],
      useFactory: (appSettingsService: AppSettingsService) => {
        return () => {
          //Make sure to return a promise!
          return appSettingsService.loadAppLocaleData();
        };
      }
    },
    ValidGuard,
    IteroUserGuard,
    SessionStorageGuard
  ]
})
export class CoreModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
