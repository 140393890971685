import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@models/app-settings.model';
import { LocaleData } from '@models/locale-data.model';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

    private settings = {} as AppSettings;
    private localeData = {} as LocaleData;

    constructor(private http: HttpClient) { }

    loadAppSettings(): Promise<void> {
      return this.http.get('/assets/config/app.settings.json')
        .toPromise()
        .then((data: any) => {
          this.settings = data;
        });
    }

    loadAppLocaleData(): Promise<void> {
      return this.http.get('/assets/locale/locale.json')
        .toPromise()
        .then((data: any) => {
          this.localeData = data;
        });
      }

    get appSettings(): AppSettings {
      if (!this.settings) {
        throw Error('App Settings not loaded!');
      }
      return this.settings;
    }

    get appLocaleData(): LocaleData {
      if (!this.localeData) {
        throw Error('Locale Data not loaded');
      }

      return this.localeData;
    }
}
