import { Injectable } from '@angular/core';
import { ApiRoutes } from '@core/api-routes/api-routes.service';
import { HttpHandlerService } from '@core/services/http-handler/http-handler.service';
import { IteroUserMetaData } from '@models/itero-user.model';
import { SignUpRequest } from '@models/signup.model';
import { Observable, of } from 'rxjs';
import { constants } from '@shared/constants';

@Injectable({
  providedIn: 'root'
})
export class ActivateService {

  get metadataToken(): string {
    return sessionStorage.getItem(constants.metadataToken) ?? '';
  }

  constructor(private httpHandler: HttpHandlerService) { }

  signup(request: SignUpRequest): Observable<boolean>{
    return this.httpHandler.post<boolean>(ApiRoutes.Activate.signup(), request);
  }

  getSecurityQuestions(): Observable<string[]>{
    return of(['Question A', 'Question B', 'Question C']);
  }

  checkIteroUser(companyId: number): Observable<IteroUserMetaData> {
    return this.httpHandler.get<IteroUserMetaData>(
      ApiRoutes.Activate.checkIteroUser(this.metadataToken, companyId)
    );
  }
}
