import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'activate',
        loadChildren: () => import('./pages/activate-account/activate-account.module').then(m => m.ActivateModule)
      },
      {
        path: 'expired',
        loadChildren: () => import('./pages/invitation-expired/invitation-expired.module').then(m => m.InvitationExpiredModule)
      },
      {
        path: 'otp',
        loadChildren: () => import('./pages/otp-verification/otp-verification.module').then(m => m.OtpVerificationModule)
      },
      {
        path: 'signup',
        loadChildren: () => import('./pages/sign-up/sign-up.module').then(m => m.SignUpModule)
      },
      {
        path: 'completed',
        loadChildren: () => import('./pages/invitation-completed/invitation-completed.module').then(m => m.InvitationCompletedModule)
      },
      {
        path: 'registrationcompleted',
        loadChildren: () => import('./pages/registration-completed/registration-completed.module').then(m => m.RegistrationCompletedModule)
      },
      {
        path: 'error',
        loadChildren: () => import('./pages/errors/global-error.component.moudle').then(m => m.GlobalErrorModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
