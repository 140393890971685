import { LogLevel, AppenderType } from '@itero/timber/dist/enums';
import { IRemoteConfig } from '@itero/timber/dist/interfaces/config.remote';

export const loggerConfigRemote: IRemoteConfig = {
	appId: 'registration-app',
	minLogLevel: LogLevel.Debug,
	appenderTypes: [ AppenderType.Remote, AppenderType.ConsoleNative ],
	url: '',
	requiredFields: ['appId', 'module']
};
