import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingsService } from '@core/services/app-settings/app-settings.service';
import { HttpStatusCode } from '@shared/enums';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpHandlerService {

  constructor(private httpClient: HttpClient,
    private appSettingsService: AppSettingsService,
    private router: Router) { }

  get<T>(url: string, options?: any): Observable<T> {
    const apiUrl = this.appSettingsService.appSettings.invitationsApiUrl.concat(url);

    return this.httpClient
      .get(apiUrl, { ...options })
      .pipe(
        tap((res: any) => this.handleResponse(res)),
        catchError((e) => this.handleError.call(this, e)));
  }

  post<T>(url: string, body?: any, options?: any): Observable<T> {
    const apiUrl = this.appSettingsService.appSettings.invitationsApiUrl.concat(url);

    return this.httpClient
    .post(apiUrl, body, { ...options })
    .pipe(
      tap((res: any) => this.handleResponse(res)),
      catchError((e) => this.handleError.call(this, e)));
  }

  handleResponse(res: any){
  }

  handleError(err: any) {
    switch(err.status){
      case HttpStatusCode.Unauthorized:
        this.router.navigate(['/']);
    }
    return throwError(err);
  }
}
