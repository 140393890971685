export class ApiRoutes {

  public static Invitations = class {
    private static domain = 'v1/invitations';

    static validate = (metadataToken: string) => `${ApiRoutes.Invitations.domain}/validate?metadataToken=${metadataToken}`;
    static get = (metadataToken: string) => `${ApiRoutes.Invitations.domain}?metadataToken=${metadataToken}`;
    static send = () => `${ApiRoutes.Invitations.domain}`;
  }

  public static Activate = class {
    private static domain = '/activate';

    static signup = () => `v1${ApiRoutes.Activate.domain}/sign-up`;
    static checkIteroUser = (metadataToken: string, companyId: number) => `v2${ApiRoutes.Activate.domain}/itero-user?metadataToken=${metadataToken}&companyId=${companyId}`;
  }

  public static InvitationsOtp = class {
    private static domain = 'v1/invitations-otp';
    static sendOtpSms = (metadataToken :string) => `${ApiRoutes.InvitationsOtp.domain}/send?metadataToken=${metadataToken}`;
    static validateOtp = (code: string, metadataToken :string) => `${ApiRoutes.InvitationsOtp.domain}/validate?code=${code}&metadataToken=${metadataToken}`;
  }
}